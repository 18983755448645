import { Box, Text, Image, Tooltip, IconButton } from '@chakra-ui/react'
import React from 'react'
import { LockIcon } from '@chakra-ui/icons';
import { clrs } from '../Constants/Constants'

const Header = ({ Lstore, setModalLogout }) => {
    return (
        <Box h={'5vh'} display={'flex'} flexDir={'row'} justifyContent={'space-between'} alignItems={'center'} paddingX={5}>
            <Text style={{ color: clrs.white, fontSize: '15px', fontWeight: 'bold' }}>Hi {Lstore ? JSON.parse(Lstore).un : ""} !</Text>

            <Box justifyContent={'center'} alignItems={'center'} display={'flex'}>
                <Image
                    src='/logo.png'
                    height={'40px'}
                    w={'40px'}
                />
            </Box>
            <Tooltip
                label='Log Out' placement='right'
            >
                <IconButton
                    onClick={() => setModalLogout(true)}
                    icon={
                        <LockIcon
                            boxSize={"20px"}
                            color={'red.700'}
                        />}
                    marginLeft={2}
                    marginTop={2}
                    h={'35px'}
                    bgColor={'blackAlpha.400'}
                />
            </Tooltip>
        </Box>
    )
}

export default Header